import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [30,[2]],
		"/admin": [38,[3]],
		"/admin/adiante/[id]/edit": [39,[3]],
		"/admin/admin_users/[id]/edit": [40,[3]],
		"/admin/billing-previews": [41,[3]],
		"/admin/cceal-ccee-energy-amounts": [42,[3]],
		"/admin/ccee-contracts": [43,[3]],
		"/admin/ccee-contracts/[slug]": [44,[3,4]],
		"/admin/ccee-settlements": [45,[3]],
		"/admin/cdes": [46,[3]],
		"/admin/companies": [47,[3]],
		"/admin/companies/new": [68,[3]],
		"/admin/companies/[id]": [48,[3,5]],
		"/admin/companies/[id]/consumer-units": [49,[3,5,6]],
		"/admin/companies/[id]/consumer-units/new": [59,[3,5,6]],
		"/admin/companies/[id]/consumer-units/[slug]": [50,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/edit": [51,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/fattureweb-distributor-credentials/edit": [52,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/financial-obligations/new": [53,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/migration-tasks": [54,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings": [55,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/new": [57,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/[monthlySavingId]/edit": [56,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/viability-studies/new": [58,[3,5,6,7]],
		"/admin/companies/[id]/contracts/new": [60,[3,5]],
		"/admin/companies/[id]/edit": [61,[3,5]],
		"/admin/companies/[id]/financial-obligations/new": [62,[3,5]],
		"/admin/companies/[id]/managerial-reports": [63,[3,5]],
		"/admin/companies/[id]/proposals/new": [64,[3,5]],
		"/admin/companies/[id]/saving-reports": [65,[3,5]],
		"/admin/companies/[id]/saving-reports/new": [66,[3,5]],
		"/admin/companies/[id]/users": [67,[3,5,8]],
		"/admin/contract-terms/[id]/edit": [69,[3,9]],
		"/admin/contract-thresholds": [70,[3]],
		"/admin/contract-thresholds/[contractId]/[referenceMonth]": [71,[3,10]],
		"/admin/contracts": [72,[3]],
		"/admin/contracts/[id]": [73,[3,11]],
		"/admin/contracts/[id]/edit": [74,[3,11]],
		"/admin/contracts/[id]/terms/new": [75,[3,11]],
		"/admin/devecs": [76,[3]],
		"/admin/distributor-companies": [77,[3]],
		"/admin/distributor-companies/[id]": [78,[3,12]],
		"/admin/distributor-companies/[id]/edit": [79,[3,12]],
		"/admin/fattureweb-interactions": [80,[3]],
		"/admin/fattureweb-interactions/[id]": [81,[3,13]],
		"/admin/financial-calendar-events": [82,[3]],
		"/admin/financial-obligations": [83,[3]],
		"/admin/financial-obligations/new": [86,[3]],
		"/admin/financial-obligations/[id]": [84,[3,14]],
		"/admin/financial-obligations/[id]/edit": [85,[3,14]],
		"/admin/hourly-average-co2-emission-factors": [87,[3]],
		"/admin/hourly-plds": [88,[3]],
		"/admin/imports": [89,[3]],
		"/admin/imports/new": [91,[3]],
		"/admin/imports/[id]": [90,[3,15]],
		"/admin/ipca-indices": [92,[3]],
		"/admin/leads": [93,[3]],
		"/admin/login": [94,[3,16]],
		"/admin/login/password-reset/new": [96,[3,16]],
		"/admin/login/password-reset/[token]": [95,[3,16]],
		"/admin/logout": [97,[3]],
		"/admin/mail-recipients/[id]/mail-subscriptions": [98,[3,17]],
		"/admin/measurements": [99,[3]],
		"/admin/monthly-average-co2-emission-factors": [100,[3]],
		"/admin/monthly-consumption": [101,[3,18]],
		"/admin/monthly-plds": [102,[3]],
		"/admin/operations": [103,[3]],
		"/admin/operations/new": [106,[3]],
		"/admin/operations/[id]": [104,[3,19]],
		"/admin/operations/[id]/edit": [105,[3,19]],
		"/admin/optimal-demand-simulations/new": [107,[3]],
		"/admin/optimal-tariff-modality-simulations/new": [108,[3]],
		"/admin/partners": [109,[3]],
		"/admin/proinfa-ccee-energy-amounts": [110,[3]],
		"/admin/proposals": [111,[3]],
		"/admin/proposals/[id]": [112,[3,20]],
		"/admin/renewable-energy-certificates": [113,[3]],
		"/admin/sendgrid-events": [114,[3]],
		"/admin/sendgrid-events/[id]": [115,[3,21]],
		"/admin/state-taxes": [116,[3]],
		"/admin/status-aggregated-measurements": [117,[3]],
		"/admin/tariff-flags-terms": [120,[3]],
		"/admin/tariff-flags": [118,[3]],
		"/admin/tariff-flags/new": [119,[3]],
		"/admin/unauthorized": [121,[3]],
		"/admin/users": [122,[3]],
		"/admin/users/[id]": [123,[3,22]],
		"/admin/users/[id]/accesses": [124,[3,22]],
		"/admin/viability-studies": [125,[3]],
		"/admin/viability-studies/new": [129,[3]],
		"/admin/viability-studies/[id]": [126,[3,23]],
		"/admin/viability-studies/[id]/document": [127],
		"/admin/viability-studies/[id]/new": [128,[3,23]],
		"/agrishow": [130],
		"/cadastro/continuar/[token]/[userId]": [131,[24]],
		"/cadastro/empresa/completo": [132,[24,25]],
		"/cadastro/empresa/editar": [133,[24,25]],
		"/cadastro/empresa/endereco/editar": [134,[24,25]],
		"/cadastro/empresa/representantes": [135,[24,25]],
		"/cadastro/empresa/representantes/novo": [137,[24,25]],
		"/cadastro/empresa/representantes/[id]/editar": [136,[24,25]],
		"/cadastro/empresa/unidades-consumidoras": [138,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/nova": [141,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/editar": [139,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/endereco/editar": [140,[24,25]],
		"/cadastro/empresa/usuarios": [142,[24,25]],
		"/cadastro/empresa/usuarios/novo": [143,[24,25]],
		"/cadastro/obrigado": [144,[24]],
		"/cadastro/uc/fatura/email": [145,[24]],
		"/cadastro/uc/fatura/nova": [146,[24]],
		"/cadastro/uc/informe-de-dados": [147,[24]],
		"/cadastro/uc/nova": [148,[24]],
		"/cadastro/uc/unidades": [149,[24]],
		"/cadastro/usuario/novo": [150,[24]],
		"/(site)/contato": [31,[2]],
		"/empresa-b": [~151],
		"/(site)/mercado-livre-de-energia": [32,[2]],
		"/painel/login": [162,[26,28]],
		"/painel/logout": [163,[26]],
		"/painel/senha/definida": [164,[26,29]],
		"/painel/senha/nova": [165,[26,29]],
		"/painel/senha/nova/[token]": [166,[26,29]],
		"/painel/[companyId]": [152,[26,27]],
		"/painel/[companyId]/bi": [153,[26,27]],
		"/painel/[companyId]/consumo": [154,[26,27]],
		"/painel/[companyId]/economia": [155,[26,27]],
		"/painel/[companyId]/mercado-livre": [156,[26,27]],
		"/painel/[companyId]/meus-dados": [157,[26,27]],
		"/painel/[companyId]/migracao": [158,[26,27]],
		"/painel/[companyId]/minha-empresa": [159,[26,27]],
		"/painel/[companyId]/pagamentos": [160,[26,27]],
		"/painel/[companyId]/sustentabilidade": [161,[26,27]],
		"/(site)/parcerias": [33,[2]],
		"/(site)/sobre-nos": [34,[2]],
		"/(site)/solucoes": [35,[2]],
		"/(site)/sustentabilidade": [36,[2]],
		"/(site)/termo-de-adesao": [~37,[2]],
		"/w": [167]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';